import refsCommon from "./refsCommon";

const { arrowUpBtn } = refsCommon;

// Функция для проверки прокрутки
export function arrowScroll() {
	if (window.scrollY > 600) {
		arrowUpBtn.classList.add("show"); // Добавляем класс visible
		arrowUpBtn.style.visibility = "visible"; // Добавляем класс visible
	} else {
		arrowUpBtn.classList.remove("show"); // Добавляем класс visible
	}
}

// Слушаем событие прокрутки
// window.addEventListener("scroll", arrowScroll);
