import refsCommon from "./refsCommon";

const { header } = refsCommon;

// Функция для проверки прокрутки
export function fixedHeader() {
	if (window.scrollY > 100) {
		header.classList.add("fixed"); // Добавляем класс visible
	} else {
		header.classList.remove("fixed"); // Добавляем класс visible
	}
}

// Слушаем событие прокрутки
// window.addEventListener("scroll", arrowScroll);
