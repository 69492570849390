const refsCommon = {
	// //modals
	acceptBtnCookie: document.getElementById("acceptCookie"),
	deleteBtnCookie: document.getElementById("deleteCookie"),

	// //sections

	// forms
	form: document.getElementById("form"),

	// arrow up
	arrowUpBtn: document.getElementById("arrowUpBtn"),

	//header
	header: document.getElementById("header"),
};

export default refsCommon;
